/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
  font-family: "Poppins", sans-serif;
}
.bgColor {
  background-color: #e60084;
}

#bgColor {
  background-color: #e60084;
}
.aboutUsBgColor1 {
  background-color: #e60084;
}
.aboutUsBgColor2 {
  background-color: #e22590;
}
.fgColorF {
  color: #e60084;
}
.fgColor {
  color: white;
}
.border {
  border: 1px solid #e60084;
}
.hover:hover {
  background-color: #c1006d;
  color: white;
}

.button {
  background-color: #e60084;
}
.button:hover {
  background-color: #c1006d;
  color: white;
}
.buttonOutline {
  border: 1px solid #e60084;
  color: #e60084;
}
.buttonOutline:hover {
  background-color: #e60084;
  color: white;
}

.themeToggleNight {
  border: 1px solid #e60084;
  color: #e60084;
}
.themeToggleDay {
  border: 1px solid #e60084;
  color: orange;
}
.checkbox :checked {
  background-color: #e60084;
  border: 1px solid #e60084;
}

.mantine-Slider-bar {
  background-color: #e60084;
}

.mantine-Slider-thumb {
  background-color: white;
  border: 3px solid #e60084;
  /* padding: 5px; */
  padding: 5px;
}

.mantine-Slider-label {
  background-color: #e60084;
}

/* .mantine-SegmentedControl-controlActive {
  background-color: #e60084;
  color: white;
} */
.mantine-SegmentedControl-active {
  background-color: #e60084;
  color: white;
}
.mantine-SegmentedControl-label {
  color: black;
}

.mantine-SegmentedControl-labelActive {
  color: white;
}

.mantine-SegmentedControl-labelActive:hover {
  color: white;
}
.mantine-Select-item :checked {
  background-color: #e60084;
  color: white;
}
.checkbox :checked {
  background-color: #e60084;
  border: 1px solid #e60084;
}

.mantine-Slider-bar {
  background-color: #e60084;
}

.mantine-Slider-thumb {
  background-color: white;
  border: 3px solid #e60084;
  /* padding: 5px; */
  padding: 5px;
}

.mantine-Slider-label {
  background-color: #e60084;
}

.selectedChat {
  background-color: #e60084;
  color: white;
}
.selectedChat:hover {
  background-color: #ca0273;
  color: white;
}
.unSelectedChat {
  border: 1px solid #e60084;
  background-color: white;
  color: black;
}
.unSelectedChat:hover {
  background-color: rgb(230, 0, 130, 0.8);
  color: white;
}

.mantine-Spoiler-control {
  color: #e60084;
}

.mantine-Menu-dropdown {
  border: 1px solid #e60084;
}

#mv-demo {
  height: 100%;
  width: 100%;
  background-color: gray;
}
/* .mantine-Tabs-tab {
  background-color: #e60084;
  color: white;
} */

/* .navLink {
  background-color: #e60084;
} */
