.table .cellWrapper {
  display: flex;
  align-items: center;
}
.table .cellWrapper .image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}
.imageing {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}
.status {
    /* padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px; */
  }

  .status.true {
    color: rgb(255, 255, 255);
    background-color: #34a853;
  }
  .status.false {
    color: rgb(255, 255, 255);
    background-color: #dc3545;
  }
