.xyz {
  /* width: 100px; */
  /* height: 100px; */
  /* background-color: red; */
  position: relative;
  animation-name: example;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
}
.xyz:hover {
  animation-name: example;
  animation-duration: 0.9s;
  animation-iteration-count: 0;
}

@keyframes example {
  0% {
    /* background-color: red; */
    /* left: 0px; */
    /* top: 0px; */
    padding-top: 0%;
  }
  10% {
    /* background-color: red; */
    /* left: 0px; */
    /* top: 0px; */
    padding-top: 10%;
  }
  20% {
    /* background-color: red; */
    /* left: 0px; */
    /* top: 0px; */
    padding-top: 20%;
  }
  30% {
    /* background-color: red; */
    /* left: 0px; */
    /* top: 0px; */
    padding-top: 30%;
  }
  40% {
    /* background-color: red; */
    /* left: 0px; */
    /* top: 0px; */
    padding-top: 40%;
  }
  50% {
    /* background-color: red; */
    /* left: 0px; */
    /* top: 0px; */
    padding-top: 50%;
  }

  60% {
    /* background-color: blue; */
    padding-top: 40%;
  }
  70% {
    /* background-color: green; */
    padding-top: 30%;
  }
  80% {
    /* background-color: red; */
    padding-top: 20%;
  }
  90% {
    /* background-color: red; */
    padding-top: 10%;
  }
  100% {
    /* background-color: red; */
    padding-top: 0%;
  }
}
